// Default Ant Design style
@import "~antd/dist/antd.less";

.ant-page-header-heading-left {
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 24px;
}

.ant-page-header-heading-sub-title {
	line-height: 1;
	padding-left: 16px;
}

.ant-page-header-heading-title {
	padding-left: 16px;
}

.ant-statistic-content {
	font-size: 18px;
}

.ant-page-header-back {
	position: absolute;
	line-height: 52px;
	margin-left: -16px;
}

.ant-drawer-body {
	padding-top: 0px;
}

.card-statistics {
	max-width: 600px;
	margin: auto;
}

.stat-icon {
	width: 16px;
}

@primary-color: #77bbff;